import React from 'react'
import { useLottie } from "lottie-react";
import animation from '../../json/animation.json'
const Animation = () => {

    const options = {
        animationData: animation,
        loop: true,
        autoplay: true, // Ensure autoplay is enabled

    };
    const { View } = useLottie(options);
    return(
        <div>
        {View }
        </div>
    )
}

export default Animation