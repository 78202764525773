import React, { useEffect, useState } from 'react'
import { cpf } from 'cpf-cnpj-validator';
import { ToastContainer, toast } from 'react-toastify'
import validator from 'email-validator';

import InputAccount from '../InputAccount'
import Animation from '../Animation'
import api from '../../services/api'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
const ContainerAccount = ({ route }) => {

    const navigate = useNavigate()

    const [name, setName] = useState()
    const [mail, setMail] = useState()
    const [userCpf, setUserCpf] = useState()
    const [phone, setPhone] = useState()
    const [cep, setCep] = useState()
    const [street, setStreet] = useState()
    const [state, setState] = useState()
    const [city, setCity] = useState()
    const [number, setNumber] = useState()
    const [complement, setComplement] = useState()
    const [password, setPassword] = useState()
    const [confirmPassword, setConfirmPassword] = useState()
    const [animationButton, setAnimationButton] = useState(false)
    const [animationData, setAnimationData] = useState(false)
    const [brazilianStates, setBrazilianStates] = useState([])
    const [brazilianCitys, setBrazilianCitys] = useState([])
    const [userSelectState, setUserSelect] = useState()
    const [userSelectCity, setUserSelectCity] = useState()


    const getCep = async () => {
        const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`)
        if (response.status == 200) {
            setStreet(response.data.logradouro)
            setUserSelect(response.data.uf)
            setUserSelectCity(response.data.localidade)
        }

    }

    const storage = async () => {



        if (!name) {
            return toast.error("Informe um nome")
        }
        if (!mail) {
            return toast.error("Informe um email")
        }

        const mailIsValid = validator.validate(mail)

        if (mailIsValid == false) {
            return toast.error("Email inválido")
        }

        const cpfIsValid = cpf.isValid(userCpf);

        if (cpfIsValid == false) {
            return toast.error("CPF inválido")
        }


        if (!userCpf) {
            return toast.error("Informe um CPF")
        }
        if (!phone) {
            return toast.error("Informe um telefone")
        }
        if (!cep) {
            return toast.error("Informe um cep")
        }
        if (!street) {
            return toast.error("Informe uma rua")
        }
        if (!number) {
            return toast.error("Informe um número")
        }
        if (!complement) {
            return toast.error("Informe um complemento")
        }

        if (!userSelectState) {
            return toast.error("Informe um estado")
        }

        if (!userSelectCity) {
            return toast.error("Informe uma cidade")
        }

        if (!password) {
            return toast.error("Informe uma senha")
        }

        if (String(confirmPassword).length < 8) {
            return toast.error("Senha precisa ter no minimo 8 digitos")
        }
        if (!confirmPassword) {
            return toast.error("confirme sua senha")
        }

        if (password != confirmPassword) {
            return toast.error("Senhas diferentes!")
        }

        setAnimationButton(true)

        if (name == undefined || cpf == undefined || cpfIsValid == false || mail == undefined || mailIsValid == false || phone == undefined || cep == undefined) {
            setAnimationButton(false)
            return false
        }

        const response = await api.post("client/storage", {
            "name": name,
            "image": "",
            "cpf": userCpf,
            "mail": mail,
            "phone": phone,
            "cep": cep,
            "street": street,
            "number": number,
            "complement": complement,
            "neighborhood": "test",
            "state": userSelectState,
            "city": userSelectCity,
            "status": "1",
            "password_hash": confirmPassword
        })

        if(response.status == 401){
            setAnimationButton(false)
            return toast.error("Usuário já existente")
        }
        

        if (response.status == 200) {

            localStorage.setItem('@USER_TOKEN', response.data.token)
            localStorage.setItem('@USER_NAME', response.data.user.name)
            localStorage.setItem("@USER_DATA", JSON.stringify(response.data.user))

            if (route == "/payment/identification") {
                window.location.href = "/payment/product"
                return false
            }

            window.location.href = "/"
        }

    }
    useEffect(() => {
        const getBrazilianStates = async () => {
            const response = await axios.get(
                "https://servicodados.ibge.gov.br/api/v1/localidades/estados"
            );

            setBrazilianStates(response.data);
        };

        getBrazilianStates();
    }, []);

    useEffect(() => {
        const getCitys = async () => {
            const response = await axios.get(
                `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${userSelectState}/distritos`
            );


            setBrazilianCitys(response.data)
        }
        getCitys()

    }, [userSelectState])


    const handleClickHaveAccount = () => {
        if (route == "/payment/identification") {
            navigate("/payment/identification/login")
            return false
        }

        navigate("/account/login")

    }



    return (

        <div className='container_account'>
            <ToastContainer />
            <p className='textPrimary_account'>Cadastre-se</p>
            <p className='textSecondary_account' onClick={() => handleClickHaveAccount()}>Já possui uma conta?</p>


            <p className='textInformation_account'>Informações da Conta</p>
            <div className='row_inputs_account'>
                <InputAccount required={true} onChange={(e) => setName(e.target.value)} name={"Nome Completo *"} id={1} />
                <InputAccount required={true} onChange={(e) => setMail(e.target.value)} name={"Email *"} id={2} />
            </div>
            <div className='row_inputs_account'>
                <InputAccount required={true} onChange={(e) => setUserCpf(e.target.value)} name={"CPF *"} id={3} mask={"999.999.999-99"} />
                <InputAccount required={true} onChange={(e) => setPhone(e.target.value)} name={"Telefone"} id={4} mask={"(99) 99999-9999"} />
            </div>

            <div className='row_inputs_account'>
                <InputAccount required={true} onBlur={() => getCep()} onChange={(e) => setCep(e.target.value)} name={"Cep *"} id={5} mask={""} />
                <InputAccount required={true} onChange={(e) => setStreet(e.target.value)} value={street} name={"Rua"} id={6} />
            </div>

            <div className='row_inputs_account'>
                <InputAccount required={true} onChange={(e) => setNumber(e.target.value)} value={number} name={"Número *"} id={7} mask={""} />
                <InputAccount required={true} onChange={(e) => setComplement(e.target.value)} value={complement} name={"Complemento"} id={8} />
            </div>


            <div className='row_inputs_account'>
                <InputAccount required={true} value={userSelectState} onChange={(e) => setUserSelect(e.target.value)} name={"Estado *"} option={brazilianStates} id={9} mask={""} type={"select"} />
                <InputAccount required={true} value={userSelectCity} onChange={(e) => setUserSelectCity(e.target.value)} name={"Cidade *"} option={brazilianCitys} id={10} type={"select"} />
            </div>

            <div className='row_inputs_account'>
                <InputAccount required={true} typeInput={"password"} onChange={(e) => setPassword(e.target.value)} name={"Senha *"} id={9} mask={""} />
                <InputAccount required={true} typeInput={"password"} onChange={(e) => setConfirmPassword(e.target.value)} name={"Confirme sua senha *"} id={10} />
            </div>



            {animationButton ? (
                <button>
                    <div className='lottie_animation_button'>
                        <Animation />

                    </div>
                </button>
            ) : (
                <button onClick={() => storage()}>
                    Cadastrar
                </button>
            )}

            <div style={{ height: "1rem" }}></div>



        </div>


    )
}

export default ContainerAccount