import React, { useContext, useState, useEffect } from 'react'
import axios from 'axios'
import { CartContext } from '../../context/cart'
import { useNavigate } from 'react-router-dom'
import api from '../../services/api'
import InputAccount from '../InputAccount'
import TableMobile from '../TableMobile'
import InputCupom from '../InputCupom'
import cardsIcon from '../../images/svg/cardsIcon.svg'
import { toast, ToastContainer } from 'react-toastify'
import Animation from '../Animation'
const ContainerPaymentProduct = () => {
    const [animation,setAnimation] = useState(false)
    const [nameCard, setNameCard] = useState()
    const [numberCard, setNumberCard] = useState()
    const [expiration, setExpiration] = useState()
    const [year, setYear] = useState()
    const [cpf, setCpf] = useState()
    const [securityCode, setSecurityCode] = useState()
    const [selectInstallment, setSelectInstallment] = useState()
    const [installments, setInstallments] = useState([])
    const navigate = useNavigate()

    const { cart, price, cep,setCep,handleStateCart, handleFreight, priceFreight, priceAddress } = useContext(CartContext)
    const [values, setValues] = useState([])
    const [dateFreigh, setDateFreigh] = useState()

    const [brazilianStates, setBrazilianStates] = useState([])
    const [brazilianCitys, setBrazilianCitys] = useState([])


    const [userSelectState,setUserSelectState] = useState()
    const [street,setStreet] = useState()
    const [number,setNumber] = useState()
    const [zipCode,setZipCode] = useState()

    const [complement,setComplement] = useState()
    const [userSelectCity,setUserSelectCity] = useState()



    const handleBtnBuy = () => {
        navigate("/")
    }

    const handleBtnContinue = async () => {


        if(!cep){
            return toast.error("Informe um cep")
        }

        if(!street){
            return toast.error("Informe uma rua")
        }

        if(!number){
            return toast.error("Informe um número")

        }

        if(!complement){
            return toast.error("Informe um complemento")
        }

        if(!userSelectState){
            return toast.error("Informe um estado")
        }
        if(!userSelectCity){
            return toast.error("Informe uma cidade")
        }

        if (!nameCard) {
            return toast.error("Informe um nome do titular")
        }
        if (!numberCard) {
            return toast.error("Informe um número do cartão")
        }
        if (!expiration) {
            return toast.error("Informe uma validade")
        }
        if (!securityCode) {
            return toast.error("Informe um cvv")
        }
        if (!cpf) {
            return toast.error("Informe um CPF")
        }

        setAnimation(true)

        const formatedExpiration = String(expiration).split("")

        const response = await api.post("payment/storage/",
            {

                "card_number": String(numberCard).replace(" ", "").replace(" ", "").replace(" ", "").replace(" ", ""),
                "expiration_month": `${formatedExpiration[0]}${formatedExpiration[1]}`,
                "expiration_year": `${formatedExpiration[3]}${formatedExpiration[4]}${formatedExpiration[5]}${formatedExpiration[6]}`,
                "security_code": securityCode,
                "cardholder": nameCard,
                "cpfNumber": cpf,
                "street":street,
                "number":number,
                "complement":complement,
                "state":userSelectState,
                "city":userSelectCity,
                "zipCode": cep,
                "cart": JSON.parse(localStorage.getItem("@LOCAL_CART")),
                "cpf": cpf,
                "installments": selectInstallment,
                "status": 1,
            }

        )

        if(response.status == 200){
            localStorage.removeItem("@LOCAL_CART")
            window.location.href="/dashboard"
        }else{
            setAnimation(false)
            toast.error("Opss.. algo deu errado")
        }

    }

    useEffect(() => {
        const getBrazilianStates = async () => {
            const response = await axios.get(
                "https://servicodados.ibge.gov.br/api/v1/localidades/estados"
            );

            setBrazilianStates(response.data);
        };

        getBrazilianStates();
    }, []);

    useEffect(() => {
        const getCitys = async () => {
            const response = await axios.get(
                `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${userSelectState}/distritos`
            );


            setBrazilianCitys(response.data)
        }
        getCitys()

    }, [userSelectState])


    useEffect(() => {

        const array = []

        for (let index = 0; index <= 12; index++) {
            if (index == 0) {
                continue;
            }
            array.push({ nome: index })
        }

        setInstallments(array)

    }, [])

    const getCep = async (cep) => {

        handleFreight(cep)

        const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`)
        if (response.status == 200) {
            setStreet(response.data.logradouro)
            setUserSelectState(response.data.uf)
            setUserSelectCity(response.data.localidade)
        }

    }

    return (

        <div className='container_payment'>
            {animation == true 
            ?
        
            <div className='container_animation_pages'>
                <div>
                    <Animation />
                </div>
            </div>              
            :
            <></>
            }


            <ToastContainer />
            <div className='box_container_payment'>
                <div className='clean_mobile_payment'></div>

                <div className='container_primary_payment'>
                    <p>Informações entrega</p>

                    <div className='row_input_address_payment'>
                        <InputAccount typeInput={"tel"}  required={true} onBlur={(e) => getCep(e.target.value)}  onChange={(e) => setZipCode(e.target.value)} name={"Cep *"}  id={5} mask={""} />
                        <InputAccount name="Rua" onChange={(e) => setStreet(e.target.value)} value={street} required={true} />
                    </div>

                    <div className='row_input_address_payment'>
                        <InputAccount name="Número"  onChange={(e) => setNumber(e.target.value)} required={true} />
                        <InputAccount name="Complemento" onChange={(e) => setComplement(e.target.value)} required={true} />
                    </div>

                    <div className='row_input_address_payment'>
                        <InputAccount required={true}   name={"Estado *"} option={brazilianStates} value={userSelectState} id={9} mask={""} type={"select"} />
                        <InputAccount required={true}  name={"Cidade *"} option={brazilianCitys}  value={userSelectCity} id={10} type={"select"} />
                    </div>
                </div>


                <div className='container_primary_payment'>
                    <p>Informações Pagamento</p>
                    <img src={cardsIcon} />
                    <div className='row_input_address_payment'>
                        <InputAccount required={true} name={"Nome Titular *"} id={5} mask={""} onChange={(e) => setNameCard(e.target.value)} />
                        <InputAccount name="Número Cartão" mask={"9999 9999 9999 9999"} onChange={(e) => setNumberCard(e.target.value)} required={true} />
                    </div>

                    <div className='row_input_address_payment'>
                        <InputAccount name="Validade" mask={"99/9999"} onChange={(e) => setExpiration(e.target.value)} required={true} />
                        <InputAccount name="Cvv" mask={"999"} onChange={(e) => setSecurityCode(e.target.value)} required={true} />
                    </div>

                    <div className='row_input_address_payment'>
                        <InputAccount name="CPF titular" mask={"999.999.999-99"} onChange={(e) => setCpf(e.target.value)} required={true} />
                        <InputAccount required={true} onChange={(e) => setSelectInstallment(e.target.value)} value={"Selecione"} name={"N parcelas *"} option={installments} id={9} mask={""} type={"select"} />

                    </div>
                </div>


            </div>


            <div className='container_secondary_payment'>

                <InputCupom name={"Código Cupom"} required={true} />
                <div className='flex_container_secondary_payment'>
                    <p className='text_primary_flex_secondary_payment'>Subtotal</p>
                    <p className='text_secondary_flex_secondary_payment'>R$ {Number(price).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })} </p>
                </div>

                <div className='flex_container_secondary_payment'>
                    <p className='text_primary_flex_secondary_payment'>Entrega</p>
                    <p className='text_secondary_flex_secondary_payment'>R$ {priceFreight == 0 ? priceAddress : priceFreight}</p>
                </div>

                <div className='flex_container_secondary_payment'>
                    <p className='text_primary_flex_secondary_payment'>Total</p>
                    <p className='text_secondary_flex_secondary_payment'>R$ {Number(price).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })} </p>
                </div>

                <button className='btn_primary_payment' onClick={() => handleBtnContinue()}>Finalizar</button>
                <button className='btn_secondary_payment' onClick={() => handleBtnBuy()}>Continuar comprando</button>

            </div>

        </div>

    )
}

export default ContainerPaymentProduct