import React, { useEffect, useState, useContext } from 'react';
import api from '../../services/api'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import deliveryIcon from '../../images/png/entrega-rapida.png';
import Input from '../Input';
import ReactPlayer from 'react-player';
import { Editor, EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import "draft-js/dist/Draft.css"; // Importe os estilos básicos do Draft.js aqui
import { Routes, useNavigate } from 'react-router-dom';
import CardVariation from '../CardVariation';
import Select from '../Select';
import SelectVariation from '../SelectVariation';
import SelectFilterSidebar from '../SelectFilterSidebar';
import { CartContext } from '../../context/cart';
import Animation from '../Animation'
import { FilterContext } from '../../context/filter';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // Requer um carregador
import { Carousel } from 'react-responsive-carousel';

const ContainerProduct = () => {

    const { variationImages,priceVariation,userSelectVariation,userSelectValue} = useContext(FilterContext)
    const { handleAddToCart } = useContext(CartContext)

    const handleTouchStart = (e) => {
        e.stopPropagation();
    };

    const handleTouchMove = (e) => {
        if (e.cancelable) {
            e.stopPropagation();
        }
    };
    const navigate = useNavigate()
    const [animation, setAnimation] = useState(true)
    const [zipCode, setZipCode] = useState()
    const [dateFreigh, setDateFreigh] = useState()
    const [priceFreigh, setPriceFreigh] = useState()
    const [freight, setFreight] = useState(false)
    const [data, setData] = useState([]);
    const [price, setPrice] = useState();
    const [promotionalPrice, setPromotionalPrice] = useState();
    const [variationsImage, setVariationsImage] = useState([])
    const [variationdata, setVariationData] = useState([])
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty()); // Estado inicial vazio do Editor

    const CustomArrow = ({ onClick, direction }) => (
        <button
            type="button"
            onClick={onClick}
            style={{
                backgroundColor: 'transparent',
                border: 'none',
                cursor: 'pointer',
                color: 'red', // Define a cor desejada aqui
                fontSize: '30px', // Tamanho da seta
                position: 'absolute',
                top: '50%',
                zIndex: "9999",
                transform: 'translateY(-50%)',
                [direction === 'left' ? 'left' : 'right']: '10px',
            }}
        >

            {direction === "left"

                ?
                <svg width="17" height="30" viewBox="0 0 17 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_2_25)">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.93 1.485C15.195 0.749996 14.01 0.749996 13.275 1.485L0.809996 13.95C0.224996 14.535 0.224996 15.48 0.809996 16.065L13.275 28.53C14.01 29.265 15.195 29.265 15.93 28.53C16.665 27.795 16.665 26.61 15.93 25.875L5.07 15L15.945 4.125C16.665 3.405 16.665 2.205 15.93 1.485Z" fill="#1D1D1D" />
                    </g>
                    <defs>
                        <clipPath id="clip0_2_25">
                            <rect width="16.5" height="30" fill="white" />
                        </clipPath>
                    </defs>
                </svg>

                :

                <svg width="17" height="30" viewBox="0 0 17 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_2_3)">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.570001 28.515C1.305 29.25 2.49 29.25 3.225 28.515L15.69 16.05C16.275 15.465 16.275 14.52 15.69 13.935L3.225 1.47C2.49 0.734997 1.305 0.734997 0.570001 1.47C-0.164999 2.205 -0.164999 3.39 0.570001 4.125L11.43 15L0.555 25.875C-0.165 26.595 -0.164999 27.795 0.570001 28.515Z" fill="#1D1D1D" />
                    </g>
                    <defs>
                        <clipPath id="clip0_2_3">
                            <rect width="16.5" height="30" fill="white" />
                        </clipPath>
                    </defs>
                </svg>


            }

        </button>
    );
    const YoutubeSlide = ({ url }) => (
        <ReactPlayer width="100%" height={"490px"} url={url} />
    );
    const ImageSlide = ({ src }) => (
        <img src={src} alt="Image" />
    );
    const getThumbnail = (url) => {
        if (url.includes('youtube')) {
            const videoId = url.split('/').pop();
            return `https://img.youtube.com/vi/${videoId}/default.jpg`;
        }
        // Se não for um vídeo do YouTube, retorna o próprio URL
        return url;
    };
    const customRenderItem = (url) => {
        if (url.includes('youtube')) {
            return <YoutubeSlide url={url} />;
        } else {
            return <ImageSlide src={url} />;
        }
    };
    const customRenderThumb = (urls) =>
        urls.map((url, index) => (
            <img key={`thumb-${index}`} src={getThumbnail(url)} alt={`Thumbnail ${index}`} />
        ));
    const getVariationData = async () => {

        const response = await api.get(`client/get/all/variations/${localStorage.getItem("@PRODUCT_ID")}`)
        console.log(response.data)
        if (response.status == 200) {
            setVariationData(response.data)
        }


    }
    const getData = async () => {
        try {
            // const data = localStorage.getItem("@PRODUCT_IMAGES");
            // const variationData = JSON.parse(localStorage.getItem("@PRODUCT_VARIATIONS"))


            if (data) {
                // const storageData = JSON.parse(data);
                // setData(storageData);

                const descriptionFromStorage = localStorage.getItem("@DESCRIPTION");
                if (descriptionFromStorage) {
                    const contentState = convertFromRaw(JSON.parse(descriptionFromStorage));
                    setEditorState(EditorState.createWithContent(contentState));
                }
            }


            setPrice(localStorage.getItem("@PRICE").replace(",", "."));
            setPromotionalPrice(localStorage.getItem("@PROMOTIONAL_PRICE").replace(",", "."));

        } catch (error) {
            console.error("Erro ao obter dados do localStorage:", error);
            // Lidar com o erro conforme necessário
        }
    };

    const handlePaymentBtn = async () => {

        if (zipCode == undefined) {
            return toast.error("Informe um cep!")
        }

        if(userSelectVariation == true ){
            const response = await api.get(`client/get/user/variation/${userSelectValue}`)
            handleAddToCart(response.data[0])
            navigate("/payment")
            return
        }

        const product = await api.get(`client/get/product/by/id/${localStorage.getItem("@PRODUCT_ID")}`)
        if (product.status == 200) {
            handleAddToCart(product.data[0])
            navigate("/payment")
        }

    }
    const handleFreight = async () => {
        if (String(zipCode).length === 9) {
            try {
                const weight = localStorage.getItem("@WEIGHT");
                if (!weight) {
                    console.error("Weight not found in localStorage");
                    return;
                }

                console.log('Sending request with:', {
                    cep: String(zipCode).replace("-", ""),
                    weight: weight
                });

                const response = await api.post("client/get/freight", {
                    cep: String(zipCode).replace("-", ""),
                    weight: weight
                });

                console.log('Response received:', response);

                if (response.status === 200) {
                    localStorage.setItem("@ZIP_CODE", zipCode);

                    setDateFreigh(response.data.prazo);
                    setPriceFreigh(response.data.vltotal);
                } else {
                    console.error("Unexpected response status:", response.status);
                }
            } catch (error) {
                if (error.response) {
                    // A resposta foi recebida e o servidor respondeu com um status diferente de 2xx
                    console.error("Error response:", error.response.data);
                } else if (error.request) {
                    // A requisição foi feita, mas não houve resposta
                    console.error("No response received:", error.request);
                } else {
                    // Alguma outra coisa causou o erro
                    console.error("Error:", error.message);
                }
            }
        }
    };

    useEffect(() => {
        if (zipCode) {
            handleFreight();
        }
    }, [zipCode]);


    useEffect(() => {
        getVariationData()
        getData();
    }, []);

    setTimeout(() => {

        setAnimation(false)
    }, [2000])




    return (
        <div>
            {animation == true

                ?
                <div className='container_animation_pages'>

                    <div>
                        <Animation />
                    </div>
                </div>
                :
                <></>
            }



            <ToastContainer />
            <div className='container_product'>
                <div className='container_product_row'>
                    <Carousel
                        showStatus={false}
                        showIndicators={false}
                        stopOnHover
                        renderArrowPrev={(onClickHandler, hasPrev, label) =>
                            hasPrev && (
                                <CustomArrow onClick={onClickHandler} direction="left" />
                            )
                        }
                        renderArrowNext={(onClickHandler, hasNext, label) =>
                            hasNext && (
                                <CustomArrow onClick={onClickHandler} direction="right" />
                            )
                        }
                    >
                        {variationImages.map((item, index) => (
                            <div
                                className='image_product_row'
                                key={index}
                                onTouchStart={handleTouchStart}
                                onTouchMove={handleTouchMove}
                            >
                                {customRenderItem(item.image)}
                            </div>
                        ))}
                    </Carousel>
                </div>
                <div>
                    {/* Restante do conteúdo do produto */}
                </div>

                <div className='container_texts'>
                    <p className='textPrimaryContainerProduct'>{localStorage.getItem("@NAME")}</p>
                    <p className='textSecondaryContainerProduct'>Disponibilidade: Pronta Entrega</p>
                    <p className='textSecondaryContainerProduct'>Capacidade de carga: 1000kg</p>
                    <p className='textPriceContainerProductPromotional'><s>De R$ {Number(promotionalPrice).toLocaleString('pt-br', { style: 'decimal', minimumFractionDigits: 2 })}</s></p>
                    <p className='textPriceContainerProduct'>Por R$ {priceVariation != 0 ?  Number(priceVariation).toLocaleString('pt-br', { style: 'decimal', minimumFractionDigits: 2 }) : Number(price).toLocaleString('pt-br', { style: 'decimal', minimumFractionDigits: 2 })}</p>

                    <p className='textDescriptionContainerProduct'></p>

                    <div className='container_frete'>

                        <img src={deliveryIcon} alt="Ícone de Entrega" />
                        <p>CALCULAR PRAZOS E PREÇOS</p>

                        <div className='row_frete'>
                            <Input onChange={(e) => setZipCode(e.target.value)} placeholder={"00000-000"} mask={"99999-999"} />
                            {/* {variationdata.length > 0 
                            
                            ?
                            <SelectVariation data={variationdata} name={"Variações"} />                        
                            :
                            <></>
                            } */}

                            <div>


                            {variationdata
                                ?.filter((item, index, self) =>
                                    index === self.findIndex((t) => t.name === item.name)
                                )
                                .map((item) => {
                                    return <CardVariation  product_id={item.product_id} key={item.name} name={item.name} value={item.value} />;
                                })}

                            </div>

                      
                        </div>



                        {dateFreigh != undefined

                            ?

                            <div className='box_freight'>
                                <p>Entrega</p>
                                <div className='container_data_freight'>
                                    <p>R$ {Number(priceFreigh).toLocaleString('pt-BR')}</p>
                                    <p>{dateFreigh} dias úteis</p>
                                </div>
                            </div>
                            :
                            <></>
                        }


                        <button className='btn_sale' onClick={() => handlePaymentBtn()}>
                            {freight == true

                                ?
                                <div className='animation_btn_sale'>
                                    <Animation />
                                </div>
                                :
                                "Comprar"

                            }

                        </button>


                    </div>



                </div>
            </div>

            <div className='description_product'>
                <p className='text_primary_description_product'>DETALHES DO PRODUTO</p>

                {/* Editor Draft.js */}
                <Editor
                    readOnly={true}
                    editorState={editorState}
                    onChange={() => <></>}
                />
            </div>
        </div>
    );
};

export default ContainerProduct;
