import React from 'react'
import InputMask from 'react-input-mask';

const Input = ({placeholder,mask,onChange,onBlur}) => {
    return(

        <InputMask onChange={onChange} type='tel' onBlur={onBlur} className='input_frete' mask={mask} placeholder={placeholder}/>

    )
}

export default Input