import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-coverflow';

// import required modules
import { Pagination, Autoplay, EffectCoverflow } from 'swiper/modules';

import imagePrimary from '../../images/png/feixe-mola.png';
import imageSecondary from '../../images/png/munheca-karmanghia.jpg';

const VerticalCarrousel = () => {
    return (
        <div className='container_vertical_carrousel'>
            <Swiper
                effect={'coverflow'}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={1}
                spaceBetween={0}
                direction='vertical'
                speed={1500} 
                coverflowEffect={{
                    rotate: 0,
                    stretch: 0,
                    depth: 200,
                    modifier: 1,
                    slideShadows: true,
                }}
                pagination={{
                    clickable: true,
                }}  
                modules={[EffectCoverflow, Pagination, Autoplay]}
                autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                }}
                className="mySwiper"
            >
                <SwiperSlide>
                    <img src={imagePrimary} alt="Imagem 1" />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={imageSecondary} alt="Imagem 2" />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={imageSecondary} alt="Imagem 2" />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={imageSecondary} alt="Imagem 2" />
                </SwiperSlide>
                {/* Adicione mais SwiperSlide se necessário */}
            </Swiper>
        </div>
    );
};

export default VerticalCarrousel;
