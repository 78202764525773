import React, { useEffect, useState } from 'react'
import api from '../../services/api'
import { FilterContext } from '../../context/filter';
import { useContext } from 'react';

const CardVariation = ({ name,value, product_id }) => {


    const {handleSelectVariation} = useContext(FilterContext)

    const [userSelectVariation,setUserSelectVariation] = useState()
    const [attributeVariations, setAttributeVariations] = useState([])
    const [selectVariation, setSelectVariation] = useState(1)

    const getVariations = async () => {
        const response = await api.post("client/get/all/images/attribute/variations", {
            "name": name,
            "product_id": product_id
        })
        if (response.status == 200) {
            setAttributeVariations(response.data)
        }
    }



    useEffect(() => {

        getVariations()

    }, [product_id])


    return (

        
        <div className='container_card_variation'>
        <p>{name}: <b style={{fontSize:"0.9rem"}}>{userSelectVariation}</b></p>

            <div className='box_card_variation'>
                <div className='row_box_card_variation'>

                    {attributeVariations?.map((item,index) => {
                        return (

                            <div className={selectVariation == index ? "checked_card_variation" : ""} onClick={() => {handleSelectVariation(item.product_id,item.value);setSelectVariation(index);setUserSelectVariation(item.value)}}>
                                <p>{item.value }</p>
                            </div>

                        )
                    })}

                    

                </div>
            </div>
        </div>

    )
}

export default CardVariation